import * as React from "react"
import { Link, useStaticQuery } from "gatsby"
import '../styles/components/Button.css'
import { AnchorLink } from "gatsby-plugin-anchor-links";


const Button = ({link, anchor, children, submit, handleSubmit}) => 

{
  if(anchor){
    return <AnchorLink className="button" to={`${link}#${anchor}`}>{children}</AnchorLink>
  }else if(submit){
    return <a className="button submit" onClick={handleSubmit}>{children}</a>
  }
  else{
    return <Link className="button" to={link}>{children}</Link>
  }
}



export default Button
