import * as React from "react"
import '../styles/components/Heading.css'
import {StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"

const Heading = ({children}) => 

{
    return(
    <div className="heading">
        <h1 data-aos="fade-left" data-aos-once className="text-center">{children}</h1>
        <div data-aos="fade-right" data-aos-once className="custom-underline">
        <StaticImage
            src="../images/rocky-mountain-web-logo-small.png"
            alt="Rocky Mountain Web Design"
            placeholder="blurred"
            layout="fixed"
            width={35}
            className="custom-underline" 
        />
        </div>
    </div>
    )
}



export default Heading
