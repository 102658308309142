/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header"
import Footer from "./Footer"
import Progress from "./Progress"
import "../styles/Layout.css"
import Aos from "aos"

const Layout = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true)

  const loader = useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 50)
  }, [])

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  React.useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])

  return (
    <>
      <Progress isAnimating={isLoading} />
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />

      {/*}<Circle />{*/}
      <div>
        <main>{children}</main>
      </div>

      <Footer siteTitle={data.site.siteMetadata?.title || `Title`} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
