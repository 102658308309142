import * as React from "react"

import "../styles/components/Menu.css"
import FancyLink from "../components/FancyLink"
import MobileMenu from "./MobileMenu"

const Menu = () => (
  <>
    <nav className="nav">
      <ul className="menu">
        <li>
          <FancyLink internalLink="/" highlightCurrent={true}>
            Home
          </FancyLink>
        </li>
        <li>
          <FancyLink internalLink="/our-work" highlightCurrent={true}>
            Our Work
          </FancyLink>
        </li>
        <li>
          <FancyLink internalLink="/services" highlightCurrent={true}>
            Our Services
          </FancyLink>
        </li>
        <li>
          <FancyLink internalLink="/contact" highlightCurrent={true}>
            Contact
          </FancyLink>
        </li>
      </ul>
    </nav>
    <MobileMenu />
  </>
)

export default Menu
