import PropTypes from "prop-types"
import { Link } from "gatsby"
import "../styles/components/MobileMenu.css"
import React from "react"
import FancyLink from "./FancyLink"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"
import { useState, useEffect, useRef } from "react"

const MobileMenu = () => {
  const menuRef = useRef()
  const [menuOpen, setMenuOpen] = useState(false)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (menuOpen) {
      setVisible(true)
    }
  }, [menuOpen])

  const fadeOutHandler = () => {
    console.log("fade out done")
    if (!menuOpen) setVisible(false)
  }

  return (
    <>
      <nav className="mobile-nav">
        <FontAwesomeIcon
          icon={faBars}
          size="2x"
          color="rgb(0, 0, 0)"
          onClick={() => {
            setMenuOpen(!menuOpen)
          }}
        />
        {visible && (
          <div
            onAnimationEnd={fadeOutHandler}
            className={`mobile-menu ${menuOpen ? "open" : "closed"}`}
          >
            <div className="mobile-menu-close">
              <FontAwesomeIcon
                icon={faTimes}
                size="2x"
                color="rgb(0, 0, 0)"
                onClick={() => {
                  setMenuOpen(!menuOpen)
                }}
              />
            </div>
            <div className="mobile-menu-inner">
              <ul>
                <li
                  onClick={() => {
                    setMenuOpen(!menuOpen)
                  }}
                >
                  <FancyLink internalLink="/" highlightCurrent={true}>
                    Home
                  </FancyLink>
                </li>
                <li
                  onClick={() => {
                    setMenuOpen(!menuOpen)
                  }}
                >
                  <FancyLink internalLink="/our-work" highlightCurrent={true}>
                    Our Work
                  </FancyLink>
                </li>
                <li
                  onClick={() => {
                    setMenuOpen(!menuOpen)
                  }}
                >
                  <FancyLink internalLink="/services" highlightCurrent={true}>
                    Our Services
                  </FancyLink>
                </li>
                <li
                  onClick={() => {
                    setMenuOpen(!menuOpen)
                  }}
                >
                  <FancyLink internalLink="/contact" highlightCurrent={true}>
                    Contact
                  </FancyLink>
                </li>
              </ul>
            </div>
          </div>
        )}
      </nav>
    </>
  )
}

export default MobileMenu
