import * as React from "react"
import { Link, useStaticQuery } from "gatsby"
import "../styles/components/Button.css"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useLocation } from "@reach/router"

const FancyLink = ({
  internalLink,
  externalLink,
  children,
  target,
  highlightCurrent,
}) => {
  const location = useLocation()
  let className

  if (highlightCurrent && location.pathname === internalLink) {
    className = `fancy-link-highlighted`
  } else {
    className = `fancy-link`
  }

  if (internalLink) {
    return (
      <Link className={className} target={target} to={`${internalLink}`}>
        {children}
      </Link>
    )
  } else {
    return (
      <a className="fancy-link" target={target} href={`${externalLink}`}>
        {children}
      </a>
    )
  }
}

export default FancyLink
