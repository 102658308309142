import * as React from "react"
import PropTypes from "prop-types"
import { Link} from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Menu from "./Menu"
import "../styles/components/Header.css"

const Header = ({ siteTitle }) => 
{
  return(
    <header className = "header">
      <div className = "flex-container header-inner"> 
      
      <Link
            to="/"
            style={{
              color: `#000`,
              textDecoration: `none`,
            }}
      >
        <StaticImage
          src="../images/rocky-mountain-web-logo-small.png"
          alt="Rocky Mountain Web Design"
          placeholder="blurred"
          layout="fixed"
          width={65}   
          className="logo"
          
          />
        </Link>
        <h1 className="companyName">
          <Link
            to="/"
            style={{
              color: `#000`,
              textDecoration: `none`,
            }}
          >
            
            {siteTitle}
          </Link>
          
        </h1>
        <Menu />
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
