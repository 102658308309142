import * as React from "react"
import PropTypes from "prop-types"
import { Link} from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import "../styles/components/Footer.css"
import { faAlignCenter } from "@fortawesome/free-solid-svg-icons"

const Footer = ({ siteTitle }) => 
{

  

  return(
    <footer className = "footer">
      <div className = "flex-container flex-wrap footer-inner"> 
      <Link
            to="/"
      >
        <StaticImage
          src="../images/rocky-mountain-web-logo-small.png"
          alt="Rocky Mountain Web Design"
          placeholder="blurred"
          layout="fixed"
          width={65}   
          className="logo"
          
          />
      </Link>
        <h1 className="companyName">
          <Link
            to="/"
          >
            
            {siteTitle}
          </Link>
          
        </h1>
        <div className="contact-details" style={{alignSelf: 'center'}}>
          <div>
            <span className="green">mail:</span> <a href="mailto:info@rockymountainweb.ca">info@rockymountainweb.ca</a>
          </div>
          <div>
            <span className="green">tel:</span> <a href="tel:1800 323 4752">1800 323 4752</a>
          </div>
        </div>
        
      </div>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
