import * as React from "react"
import { Link } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import "../styles/components/CallToAction.css"
import Button from "./Button"

const CallToAction = ({ heading, body, buttonText, bgColor, link }) => {
  return (
    <div
      data-aos="fade-in"
      style={{ backgroundColor: bgColor }}
      className="call-to-action"
    >
      <div className="inner">
        <h1>{heading}</h1>
        <p>{body}</p>
        <Button link={`/${link}`}>{buttonText}</Button>
      </div>
    </div>
  )
}

export default CallToAction
